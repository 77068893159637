
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { wait } from '~/assets/ts/utils/misc'
import { TailwindBreakpoint } from '~/assets/ts/enums'
import { clamp } from '~/assets/ts/utils/math'

export default Vue.extend({
  name: 'HorizontalScroll',
  components: { SaIcon },
  props: {
    /** This is the tailwind text size */
    arrowSize: {
      type: String,
      default: 'text-2xl',
      validator(value: string) {
        return value.includes('text-')
      },
    },
    fadeBgColor: {
      type: String,
      default: 'bg-theme',
      validator(value: string) {
        return value.includes('bg-')
      },
    },
    /** This is the tailwind gap size */
    gapSize: {
      type: String,
      default: 'gap-4',
      validator(value: string) {
        return value.includes('gap-')
      },
    },
    /** Should arrows be shown on mobile */
    mobileArrows: {
      type: Boolean,
      default: true,
    },
    /** If you want to use a custom scroll amount instead of it being generated */
    scrollAmount: {
      type: Number,
      default: undefined,
    },
    /** The identifier of an item that the scroller should automatically scroll to */
    scrollTo: {
      type: String,
      default: '.text-theme-blue',
    },
    /** Will always center the scrollTo element if possible.
     * Default is to center on small screens and not center on larger ones. */
    centerScrollTo: {
      type: Boolean,
    },
    /** By default, if a scrollTo is set, it will be scrolled to when the page loads.
     * This is good for something like tab navigation, but for something further down
     * the page it can cause the page to scroll down to the element.
     *
     * If we want to skip this initial scroll and thus skip the page scroll, use this property */
    skipInitialScroll: {
      type: Boolean,
    },
  },
  data() {
    return {
      width: 1920,
      scrollWidth: 1080,
      childWidth: 100,
      left: 0,
      mounted: false,
      resizeTimeout: 0,
    }
  },
  computed: {
    arrows(): boolean {
      return this.mobileArrows || !this.mobile
    },
    mobile(): boolean {
      return this.$device.isMobileOrTablet
    },
    scroller(): HTMLElement {
      return this.$refs.scroller as HTMLElement
    },
    scrolled(): boolean {
      return this.left > 0
    },
    small(): boolean {
      return this.width < TailwindBreakpoint.md
    },
    finished(): boolean {
      return this.left + this.width >= this.scrollWidth
    },
    /** This assumes a standard gap size can be extracted from the tailwind class */
    // gapPixels(): number {
    //   return parseInt(this.gapSize.split('gap-')[1]) * 4
    // },
    /** This is the pixel width of the gradients as defined in the CSS below */
    gradientWidth(): number {
      return this.mobile ? 32 : 64
    },
    scrollSize(): number {
      if (this.scrollAmount) return this.scrollAmount
      return this.width - this.gradientWidth * 2
      // return this.childWidth + this.gapPixels
    },
  },
  watch: {
    scrollTo() {
      this.scrollIntoView()
    },
  },
  mounted() {
    this.update()
    this.mounted = true
    if (this.skipInitialScroll) return
    this.scrollIntoView(true)
  },
  methods: {
    update() {
      if (!this.scrollAmount) {
        const children = this.scroller.children
        if (children.length) {
          this.childWidth = children[0].clientWidth
        } else {
          console.warn(
            'No children found in horizontal scroller',
            this.scroller
          )
        }
      }
      this.width = this.scroller.clientWidth
      this.scrollWidth = this.scroller.scrollWidth
      this.left = this.scroller.scrollLeft
    },
    resize() {
      clearTimeout(this.resizeTimeout)
      window.setTimeout(() => {
        this.update()
      }, 300)
    },
    scrollLeft() {
      this.scroll(-this.scrollSize)
    },
    scrollRight() {
      this.scroll(this.scrollSize)
    },
    scroll(amount: number) {
      if (!this.arrows) return
      this.update()
      const left = clamp(this.left + amount, -1, this.scrollWidth + 1)
      this.scroller.scrollTo({ left, behavior: 'smooth' })
      // this.scroller.scrollBy({ left: amount, behavior: 'smooth' })
    },
    async scrollIntoView(initial = false) {
      if (!this.scrollTo) return
      const child = this.scroller.querySelector(this.scrollTo)
      if (!child) return
      if (initial) {
        await wait(50)
      }
      child.scrollIntoView({
        behavior: 'smooth',
        inline: this.small || this.centerScrollTo ? 'center' : 'nearest', // Centers the element horizontally
        block: 'nearest', // Keeps the whole page from scrolling
      })
    },
  },
})
