
import Vue from 'vue'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SaLinkOrSpan',
  props: {
    to: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    props(): Record<string, any> {
      return this.to ? { to: this.to } : {}
    },
  },
})
