var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.siteButton ? _vm.SiteButton : 'button',{tag:"component",staticClass:"text-theme",class:[
    _vm.button ? 'text-button' : '',
    _vm.isLoading ? 'loading-placeholder' : '',
    _vm.isFollowed ? 'followed' : 'not-followed',
    _vm.initialized ? '' : 'pointer-events-none',
    _vm.siteButton ? 'text-theme' : '',
  ],attrs:{"size":_vm.size},on:{"click":_vm.clicked}},[(_vm.icon)?_c('InlineIcon',{attrs:{"icon":_vm.iconType,"slot-classes":_vm.removeText ? 'hidden' : ''}},[_vm._v(_vm._s(_vm.followText))]):_c('span',[_vm._v(_vm._s(_vm.followText))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }