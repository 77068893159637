import { FollowParams, FollowType } from '~/store/users'

export function initialFollowValue(
  props: Record<string, any>
): boolean | undefined {
  if (props.speaker) {
    return props.speaker.following
  } else if (props.broadcaster) {
    return props.broadcaster.following
  } else if (props.series) {
    return props.series.following
  } else if (props.sermon) {
    return props.sermon.favorite
  }
  return undefined
}

export function followParams(
  props: Record<string, any>
): FollowParams | undefined {
  let type = 'sermon' as FollowType
  let id = '' as string | number
  if (props.speaker) {
    type = 'speaker'
    id = props.speaker.id
  } else if (props.broadcaster) {
    type = 'broadcaster'
    id = props.broadcaster.id
  } else if (props.series) {
    type = 'series'
    id = props.series.id
  } else if (props.sermon) {
    id = props.sermon.id
  }
  if (!id) return undefined
  return {
    id,
    type,
  } as FollowParams
}
