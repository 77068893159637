
import Vue from 'vue'
import FollowButton, { FollowableProps } from '../_general/FollowButton.vue'
import {
  broadcasterShareUrl,
  siteSeriesUrl,
  siteSermonUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'

import ShareButton from '~/components/_general/ShareButton.vue'

export default Vue.extend({
  name: 'SiteFollowableButtonStack',
  components: { ShareButton, FollowButton },
  props: {
    ...FollowableProps,
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    shareUrl(): string {
      if (this.sermon) {
        return siteSermonUrl(this.sermon)
      } else if (this.broadcaster) {
        return broadcasterShareUrl(this.broadcaster)
      } else if (this.series) {
        return siteSeriesUrl(this.series)
      } else if (this.speaker) {
        return siteSpeakerUrl(this.speaker)
      }
      return ''
    },
    shareTitle(): string {
      if (this.sermon) {
        return this.sermon.shareTitle
      } else if (this.broadcaster) {
        return this.broadcaster.shareTitle
      } else if (this.series) {
        return this.series.shareTitle
      } else if (this.speaker) {
        return this.speaker.shareTitle
      }
      return ''
    },
  },
  mounted() {
    this.mounted = true
  },
})
