
import Vue from 'vue'

export default Vue.extend({
  name: 'VerticalAlign',
  props: {
    wrap: {
      type: Boolean,
    },
    ellipsis: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    wrappingClasses(): string {
      if (this.wrap) return ''
      return `${this.ellipsis ? 'ellipsis' : 'overflow-hidden'}`
    },
  },
})
