
import Vue from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import BroadcasterImage from '~/components/_general/BroadcasterImage.vue'
import { images, metadata } from '~/assets/ts/utils/metadata'
import { NavigationTabItem, NavigationTabs } from '~/assets/ts/enums'
import { siteBroadcasterUrl } from '~/assets/ts/utils/urls'
import SiteLayoutDefault from '~/components/site/layout/Default.vue'
import SiteLayoutHero from '~/components/site/layout/Hero.vue'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import LiveIndicator from '~/components/_general/LiveIndicator.vue'
import LivePoller from '~/components/_general/LivePoller.vue'
import SiteFollowableButtonStack from '~/components/site/FollowableButtonStack.vue'
import SaImage from '~/components/_general/SaImage.vue'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import { AlertButton } from '~/models/generic/alert-button'
import SaIcon from '~/components/_general/SaIcon.vue'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteManageButtons from '~/components/site/ManageButtons.vue'
import { BroadcasterRoles } from '~/models/users/roles'
import NavigationTabList from '~/components/_general/NavigationTabList.vue'
import { clone } from '~/assets/ts/utils/misc'
import { GetNavigationSlug } from '~/assets/ts/utils/route'
import { LivePollerBroadcasterFrequency } from '~/assets/ts/utils/webcasts'

enum BroadcasterTabType {
  Home = '',
  Sermons = 'sermons',
  Series = 'series',
  Speakers = 'speakers',
  Comments = 'comments',
  Notices = 'notices',
  Articles = 'articles',
  Disabled = 'disabled',
}

export default Vue.extend({
  components: {
    NavigationTabList,
    SiteManageButtons,
    InlineIcon,
    CustomDropdown,
    SaIcon,
    SaImage,
    SiteFollowableButtonStack,
    LivePoller,
    LiveIndicator,
    VerticalAlign,
    SiteLayoutHero,
    SiteLayoutDefault,
    BroadcasterImage,
  },
  layout: 'site',
  middleware: ['fetchBroadcaster', 'fetchWebcast'],
  data() {
    return {
      polledOnline: undefined as boolean | undefined,
    }
  },
  head(this: any) {
    let title = this.broadcaster.displayName
    if (this.disabled) {
      title = this.$t('broadcaster.disabled.title')
    } else if (!this.home) {
      title = `${this.tab.title} | ${title}`
    }
    return metadata(this, {
      title,
      description: this.description,
      images: images(this.broadcaster.albumArt(500), true),
    })
  },
  computed: {
    pollFrequency(): number {
      return LivePollerBroadcasterFrequency
    },
    BroadcasterRoles() {
      return BroadcasterRoles
    },
    online(): boolean {
      return this.polledOnline ?? !!this.broadcaster?.webcastInProgress
    },
    description(): string {
      const minister = this.broadcaster?.minister
      const ministerString = minister ? `${minister} | ` : ''
      const location = this.broadcaster?.location ?? ''
      return `${ministerString}${location}`
    },
    tabList(): NavigationTabs {
      return [
        {
          title: this.$t('Home'),
          slug: BroadcasterTabType.Home,
        },
        {
          title: this.$t('Sermons'),
          slug: BroadcasterTabType.Sermons,
        },
        {
          title: this.$t('Series'),
          slug: BroadcasterTabType.Series,
        },
        {
          title: this.$t('Speakers'),
          slug: BroadcasterTabType.Speakers,
        },
        {
          title: this.$t('Articles'),
          slug: BroadcasterTabType.Articles,
        },
        {
          title: this.$t('Comments'),
          slug: BroadcasterTabType.Comments,
        },
        {
          title: this.$t('Notices'),
          slug: BroadcasterTabType.Notices,
        },
      ]
    },
    broadcasterID(): string {
      return this.$route.params.broadcasterID
    },
    broadcaster(): Broadcaster | undefined {
      const broadcasterID = this.$route.params.broadcasterID
      const data =
        this.$store.getters['broadcasters/broadcaster'](broadcasterID)
      return data ? new Broadcaster(data) : undefined
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.broadcaster)
    },
    slug(): BroadcasterTabType {
      const tabs = clone(this.tabList)
      tabs.push({
        title: '',
        slug: BroadcasterTabType.Disabled,
      })
      return GetNavigationSlug<BroadcasterTabType>(
        this,
        tabs,
        BroadcasterTabType.Home
      )
    },
    tab(): NavigationTabItem {
      return this.tabList.find((t) => t.slug === this.slug) as NavigationTabItem
    },
    home(): boolean {
      return this.slug === BroadcasterTabType.Home
    },
    disabled(): boolean {
      return this.slug === BroadcasterTabType.Disabled
    },
    longBroadcasterName(): boolean {
      if (!this.broadcaster || !this.broadcaster.displayName) return false
      return this.broadcaster.displayName.length > 48
    },
    currentMyChurchID(): string {
      return this.$users.user
        ? this.$users.user.myChurchBroadcasterID
        : this.$store.getters['local/myChurchBroadcasterID']
    },
    currentMyChurch(): Broadcaster | undefined {
      if (!this.currentMyChurchID) return undefined
      const b = this.$store.getters['broadcasters/broadcaster'](
        this.currentMyChurchID
      )
      if (!b) return undefined
      return new Broadcaster(b)
    },
    isMyChurch(): boolean {
      if (!this.broadcaster || !this.currentMyChurchID) return false
      return this.broadcasterID === this.currentMyChurchID
    },
  },
  watch: {
    online() {
      if (this.online) {
        this.$store.dispatch('webcasts/fetchWebcastsInProgress', {
          broadcasterID: this.broadcasterID,
        })
      }
    },
  },
  methods: {
    changeMyChurch(close: () => void) {
      close()
      if (!this.broadcaster) return
      if (this.isMyChurch) {
        this.removeMyChurch()
      } else {
        this.setMyChurch(!!this.currentMyChurch)
      }
    },
    setMyChurch(change = false) {
      SiteAlertModal(this, {
        title: this.$t('Set My Church'),
        text: change
          ? this.$t('mychurch.change', {
              oldBroadcaster: this.currentMyChurch?.displayName,
              newBroadcaster: this.broadcaster?.displayName,
            })
          : this.$t('mychurch.set'),
        neutral: new AlertButton({
          text: this.$t('Cancel'),
        }),
        positive: new AlertButton({
          text: this.$t('Confirm'),
          event: async () => {
            await this.setUserAndLocalMyChurch(this.broadcasterID)
          },
        }),
      })
    },
    removeMyChurch() {
      SiteAlertModal(this, {
        title: this.$t('Clear My Church'),
        text: this.$t('mychurch.remove'),
        neutral: new AlertButton({
          text: this.$t('Cancel'),
        }),
        negative: new AlertButton({
          text: this.$t('Confirm'),
          event: async () => {
            await this.setUserAndLocalMyChurch(null)
          },
        }),
      })
    },
    async setUserAndLocalMyChurch(broadcasterID: string | null) {
      if (this.$users.loggedIn) {
        await this.$users.patchCurrentUser({
          my_church_broadcaster_id: broadcasterID,
        })
      } else {
        await this.$store.dispatch(
          'local/setMyChurchBroadcasterID',
          broadcasterID
        )
      }
    },
  },
})
