
import Vue, { PropType } from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { DjangoUrl } from '~/assets/ts/utils/dashboard'
import { User } from '~/models/users/user'
import { BroadcasterRoles } from '~/models/users/roles'

export default Vue.extend({
  name: 'SiteManageButtons',
  components: { SaIcon },
  props: {
    /** If this is not provided, the dashboard url will never appear */
    broadcasterID: {
      type: String,
      default: '',
    },
    /** The min role required for the edit button */
    broadcasterRole: {
      type: Number as PropType<BroadcasterRoles>,
      default: BroadcasterRoles.Uploader,
    },
    /** /dashboard will be appended */
    dashboard: {
      type: String,
      default: '',
      validator(value: string) {
        return !value || value.startsWith('/dashboard')
      },
    },
    legacy: {
      type: String,
      default: '',
      validator(value: string) {
        return !value || value.startsWith('/')
      },
    },
    admin: {
      type: String,
      default: '',
      validator(value: string) {
        return !value || value.startsWith('/')
      },
    },
  },
  computed: {
    user(): User | undefined {
      return this.$users.user
    },
    isAdmin(): boolean {
      return !!this.user?.admin
    },
    isBroadcaster(): boolean {
      if (!this.broadcasterID || !this.user) return false
      return this.user.canEditBroadcaster(
        this.broadcasterID,
        this.broadcasterRole
      )
    },
    dashboardUrl(): string {
      return DjangoUrl(this.dashboard)
    },
    legacyUrl(): string {
      return `https://legacy.sermonaudio.com${this.legacy}`
    },
    adminUrl(): string {
      return `https://admin.sermonaudio.com${this.admin}`
    },
  },
})
