
import Vue from 'vue'
import { siteWebcastUrl } from '~/assets/ts/utils/urls'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'LiveIndicator',
  props: {
    dotOnly: {
      type: Boolean,
    },
    live: {
      type: Boolean,
    },
    broadcasterID: {
      type: String,
      default: '',
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    webcastUrl(): string | undefined {
      if (!this.broadcasterID) return undefined
      return siteWebcastUrl(this.broadcasterID)
    },
  },
})
